import { defineStore } from 'pinia'

export const useUpsellsStore = defineStore({
    id: 'upsells',
    state(){
        return {
          upsells: {
            add_to_cart: {
                title: 'Add to Cart',
                upsell_type: 'add_to_cart',
                enabled: false,
            },
          },
        }
    },
    getters: {

    },
    actions: {
      async loadUpsellsList() {
          try {
              const upsells = await this.$nuxt.$axios.$get(`${process.env.API_V1_URL}/upsells`)
              this.upsells = upsells.data
          } catch (err) {
              console.error(err)
              // Reload page on templates error
              window.location.href = '/'
          }
      },
    }
})
